<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar
        color="greyBase"
        dark
        :extended="!$vuetify.breakpoint.mobile"
        flat
        extension-height="55"
      >
        <v-toolbar-title>
          <b class="mr-3">Finance</b>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn-toggle
          mandatory
          outlined
          :light="!$store.state.darkMode"
          v-model="toggleView"
          rounded
          color="blue"
        >
          <v-btn small outlined>
            <v-icon
              v-if="!$vuetify.breakpoint.mobile"
              left
              small
              :color="toggleView == 0 ? 'blue' : 'primaryText'"
              >list</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Items</span
            >
          </v-btn>
          <!-- <v-btn small outlined>
            <v-icon
              v-if="!$vuetify.breakpoint.mobile"
              left
              small
              :color="toggleView == 1 ? 'blue' : 'primaryText'"
              >account_balance</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Invoices</span
            >
          </v-btn>
          <v-btn small outlined>
            <v-icon
              v-if="!$vuetify.breakpoint.mobile"
              left
              small
              :color="toggleView == 2 ? 'blue' : 'primaryText'"
              >payments</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Payments</span
            >
          </v-btn> -->
        </v-btn-toggle>
      </v-toolbar>

      <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
          'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
        }"
      >
        <v-card-text
          :style="{
            height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
            'overflow-y': 'auto',
          }"
        >
          <v-expand-transition> </v-expand-transition>
          <v-expand-transition>
            <div v-if="toggleView == 0">
              <AccountItems />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="toggleView == 1">
              <Invoices />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="toggleView == 2">
              <Payments />
            </div>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import AccountItems from "@/components/Finance/AccountItems.vue";
import Invoices from "@/components/Finance/Invoices.vue";
import Payments from "@/components/Finance/Payments.vue";

// import Bundles from '@/components/Finance/Bundles.vue'
// import ProductandServices from '@/components/Finance/ProductandServices.vue'
export default {
  components: {
    AccountItems,
    Invoices,
    Payments
  },
  data: () => ({
    toggleView: 0,
    loaded: true,
    tabs: ["Products & Services", "Bundles"],
  }),
};
</script>
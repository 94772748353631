<template>
    <div>
        Payments
    </div>
</template>
<script>
export default {
    data: () => ({
    })
}
</script>